import React from 'react';
import styled from '@emotion/styled';
import FooterMenu from '../Menus/FooterMenu';
import FooterVirtualSport from '../Menus/FooterVirtualSport';
import GamesSlots from '../Menus/GamesSlots';
import Racing from '../Menus/Racing';
import Lottery from '../Menus/Lottery';
import FooterLiveCasino from '../Menus/FooterLiveCasino';
import { wrapper, color, border, socialIcon, breakpoints, flex } from '../../utils/style';
import discover from '../../img/discover.svg';
import mastercard from '../../img/mastercard.svg';
import paypal from '../../img/paypal.svg';
import visa from '../../img/visa.svg';
import phone from '../../img/tel.svg';
import { mq } from '../../utils/mediaQuery';
import IcomoonIcon from '../shared/IcomoonIcon';
import { WithdrawPopup } from '../Content/Withdraw/WithdrawPage';
import FooterBall from '../shared/FooterBall';
import FreshChatComponent from '../Content/Chat/fresh-chat/FreshChat';
import dictionary from '../../utils/dictionary';
import FooterSubMenu from '../Menus/FooterSubMenu';

const Wrapper = styled('div')`
  ${wrapper}
  width: 100%;
  display: flex;
  justify-content: space-between;
  position: relative;
  border-radius: ${border.borderRadius};
  padding: 30px 16px 40px 16px;
  @media (max-width: ${breakpoints.md}) {
    flex-wrap: wrap;
    .desktop {
      display: none;
    }
    .footer-submenu {
      padding-left: 16px;
    }
  }
  @media (max-width: ${breakpoints.xs}) {
    margin: 0;
    padding: 0 16px;
  }
`;

const MenuSection = styled('div')`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: stretch;
  min-height: 240px;
  height: 240px;
  width: 57%;
  flex-direction: column;
  border-left: 1px solid ${color.borderColor};
  border-right: 1px solid ${color.borderColor};
  padding-left: 40px;
  .menu-col {
    min-height: min-content;
  }
  @media (max-width: ${breakpoints.tl}) {
    width: 47%;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: stretch;
    min-height: 355px;
  }
  @media (max-width: ${breakpoints.md}) {
    width: 100%;
    min-height: auto;
    height: auto;
    display: grid;
    grid-auto-flow: column;
    grid-template-rows: repeat(3, 40px);
    justify-content: space-between;
    padding: 25px 25px 20px 25px;
    margin: 20px 0 20px 0;
    border-top: 1px solid ${color.borderColor};
    border-bottom: 1px solid ${color.borderColor};
    justify-content: space-between;
    min-height: max-content;
    height: max-content;
    :after {
      display: none;
    }
  }
  @media (max-width: ${breakpoints.xs}) {
    border-left: none;
    border-right: none;
  }
  .h4 {
    font-weight: 400;
    color: #28b7fa;
    text-transform: uppercase;
    font-size: 15px;
    color: ${color.darkBlue};
    white-space: nowrap;
    margin: 0 10px 10px;
    position: relative;
    a {
      color: ${color.blue};
    }
    .iconImage {
      position: absolute;
      left: -28px;
    }
    & svg {
      margin-left: -19px;
      margin-right: 10px;
    }
  }
  ul {
    list-style: none;
    max-width: 75%;
  }
  li {
    position: relative;
    vertical-align: top;
    padding: 0 10px;
    margin-bottom: 0.5rem;
    font-size: 15px;
    a {
      color: ${color.grey};
    }
    a:hover,
    a:active,
    .active {
      color: ${color.white};
      text-decoration: none;
    }
    a:hover + span,
    a:active + span,
    .active + span {
      border-right: 2px solid ${color.white};
      border-bottom: 2px solid ${color.white};
    }
    a:before {
      content: '';
      height: 4px;
      width: 4px;
      position: absolute;
      left: -5px;
      top: 10px;
      display: block;
      background-color: ${color.grey};
      border-radius: 50%;
    }
    .arrow {
      width: 9px;
      height: 9px;
      position: absolute;
      left: 0;
      top: 0px;
      z-index: 1;
      transform: rotate(-45deg) translate(-14px, -7px);
      border-right: 2px solid ${color.grey};
      border-bottom: 2px solid ${color.grey};
      display: none;
    }
  }
`;

const RightsReservedItems = styled('div')`
  padding-left: 16px;
  padding-right: 16px;
  .footer-mobile {
    ${flex};
    border-top: 1px solid #2f3551;
    flex-direction: column;
    width: 100%;
    height: max-content;
    padding-top: 30px;
    margin-bottom: 50px;
    position: relative;
  }
  .social-buttons {
    margin: 3px 7px 0 0;
    padding: 5px 0 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    svg {
      vertical-align: baseline;
    }
  }
  .copy {
    height: 23px;
    background-color: var(--white);
    font-family: Kanit;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.89;
    letter-spacing: normal;
    color: ${color.grey};
  }
  @media (max-width: ${breakpoints.md}) {
    margin-top: 30px;
    margin-bottom: 80px;
    .footer-mobile {
      padding-top: 20px;
    }
  }
`;

const PaymentsVars = styled('div')`
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  .iconImage {
    max-width: 104px;
    width: 100%;
    margin-bottom: 11px;
    margin-right: 0;
    @media (max-width: ${breakpoints.md}) {
      max-width: calc(50% - 5px);
    }
  }
`;

const ContactInfo = styled('div')`
  width: 218px;
  display: flex;
  .sbobet-seo {
    padding: 10px 0 0 15px;
    .desktop {
      ul {
        list-style: none;
        a {
          position: relative;
          &:before {
            content: '';
            height: 4px;
            width: 4px;
            position: absolute;
            left: -12px;
            top: 10px;
            display: block;
            background-color: ${color.grey};
            border-radius: 50%;
          }
        }
      }
    }
  }
  @media (max-width: ${breakpoints.md}) {
    width: 100%;
    flex-direction: column;
    align-items: center;
    .sbobet-seo {
      margin-bottom: 20px;
      width: 100%;
    }
    .sbobet-seo .desktop {
      display: block;
    }
  }
  .h4 {
    margin-left: 50px;
    color: ${color.grey};
    font-weight: 400;
    @media (max-width: ${breakpoints.md}) {
      display: none;
    }
  }
  .social-buttons {
    @media (max-width: ${breakpoints.md}) {
      order: 2;
    }
    .button {
      margin: 3px 7px 15px 0;
      padding: 5px 10px 8px;
      border-radius: 50%;
      svg {
        vertical-align: baseline;
      }
    }
  }
  .copy {
    margin: 25px 0 0;
    @media (max-width: ${breakpoints.md}) {
      order: 2;
    }
  }
`;

const TextLink = styled('a')`
  ${socialIcon};
  span {
    display: inline-block;
    vertical-align: top;
    line-height: 1.2;
    color: ${color.white};
  }
  img {
    top: 0;
    left: 0;
    position: absolute;
  }
`;

const Phone = styled.div`
  height: 44px;
  background-color: transparent;
  border-radius: 4px;
  border: 1px solid #4d4d5f;
  text-align: center;
  align-items: center;
  justify-content: center;
  margin-bottom: 8px;
  display: flex;
  padding: 0 10%;
  white-space: nowrap;
  cursor: pointer;
  &:hover {
    border: none;
    background-color: rgba(255, 255, 255, 0.3);
  }
  @media (orientation: portrait) {
    ${mq('xxs')} {
      padding: 0 20%;
    }
  }
`;

const Lnwasia = styled.div`
  height: 44px;
  background-color: transparent;
  border-radius: 4px;
  border: 1px solid #00b901;
  color: #00b901;
  text-align: center;
  align-items: center;
  justify-content: space-between;
  display: flex;
  padding: 0 16%;
  cursor: pointer;
  &:hover {
    background-color: #00b901;
    color: #ffffff;
    & > svg {
      & path {
        fill: #ffffff;
      }
    }
  }
  white-space: nowrap;
  @media (max-width: ${breakpoints.xs}) {
    padding: 0 29%;
  }
  @media (max-width: ${breakpoints.xxs}) {
    padding: 0 24%;
  }
`;

// REFACTOR
// Full refactor with decompose
export default () => (
  <footer>
    <Wrapper>
      <ContactInfo>
        <div className="menu-col">
          <div className="contacts">
            <Phone onClick={() => window.open(`${dictionary.phoneLink}`)} elType="phone" rel="noopener noreferrer">
              <img src={phone} alt="phone" width="42" height="42" />
              02 508 87 98
            </Phone>
            <Lnwasia onClick={() => window.open(`${dictionary.lineLink}`)} rel="noopener noreferrer">
              <IcomoonIcon icon="line-id" size={25} />
              {dictionary.lineWithAtSign}
            </Lnwasia>
            <PaymentsVars>
              <img className="iconImage" src={visa} alt="visa" width="104" height="38" />
              <img className="iconImage" src={mastercard} alt="mastercard" width="104" height="38" />
              <img className="iconImage" src={discover} alt="discover" width="104" height="38" />
              <img className="iconImage" src={paypal} alt="paypal" width="104" height="38" />
            </PaymentsVars>
          </div>
        </div>
      </ContactInfo>
      <MenuSection>
        <div className="menu-col">
          <FooterMenu />
        </div>
        <div className="menu-col">
          <FooterVirtualSport />
        </div>
        <div className="menu-col">
          <GamesSlots />
        </div>
        <div className="menu-col">
          <FooterLiveCasino />
        </div>
        <div className="menu-col">
          <Racing />
        </div>
        <div className="menu-col">
          <Lottery />
        </div>
      </MenuSection>
      <div className="menu-col">
        <FooterSubMenu />
      </div>
    </Wrapper>
    <RightsReservedItems>
      <div className="footer-mobile">
        <div className="copy">&copy;{`${process.env.SITE_NAME_SHORT}`} 2020. All Rights Reserved &nbsp;</div>
        <div className="social-buttons">
          <TextLink className="" href={process.env.FACEBOOK_LINK} aria-label="Gclub Facebook">
            <IcomoonIcon icon="facebook" size={40} color="#0ba5ff" />
          </TextLink>
          <TextLink className="" href={process.env.TWITTER_LINK} aria-label="Gclub Twitter">
            <IcomoonIcon icon="twitter" size={40} color="#0ba5ff" />
          </TextLink>
          <TextLink className="" href={process.env.YOUTUBE_LINK} aria-label="Gclub Youtube">
            <IcomoonIcon icon="youtube" size={40} color="#0ba5ff" />
          </TextLink>
        </div>
        <FooterBall />
      </div>
    </RightsReservedItems>
    <WithdrawPopup popupId="withdraw-popup" />
    <FreshChatComponent />
  </footer>
);
